<template>
  <div class="user-page-wrapper">
    <div class="tab-block">
      <div class="tab-item" :class="{'tab-item-active': item.route === tabActiveIndex}" :key="item.label"
           v-for="(item, index) in tabs" @click="handleTabClick(item)">{{item.label}}</div>
    </div>
    <div class="route-content-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import BasicInfo from "@/page-home/views/user/components/BasicInfo";
  export default {
    name: "User",
    components: {BasicInfo},
    data() {
      return {
        tabs: [
          {label: '我的上传', route: '/user/upload'},
          {label: '我的信息', route: '/user/info'}
        ],
        tabActiveIndex: ''
      }
    },

    watch: {
      '$route' (to,from) {
        this.tabActiveIndex = to.path
      }
    },

    methods: {
      handleTabClick(tab) {
        this.$router.push({path: tab.route})
      }
    },

    created() {
      this.tabActiveIndex = this.$route.path
    }
  }
</script>

<style lang="scss" scoped>
  .user-page-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    .tab-block {
      width: 200px;
      background-color: #ffffff;
    }
    .route-content-wrapper {
      flex: 1;
      margin: 30px 30px 0;
    }
  }
  .tab-block {
    .tab-item {
      width: 178px;
      height: 44px;
      margin: 0 auto 50px;
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      text-align: center;
      line-height: 44px;
      cursor: pointer;
      &:first-child {
        margin-top: 70px;
      }
    }
    .tab-item-active {
      color: #004DF8;
      background: #EBF2FE;
      border-radius: 5px;
    }
  }
</style>
